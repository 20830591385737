import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function endsWithValidator(prop: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control?.value;

    if (!value?.endsWith(prop)) {
      return { ends_with: prop };
    }

    return null;
  };
}
