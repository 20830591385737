import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { endsWithValidator } from '../validators/ends_with.validator';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, pairwise, startWith, takeUntil } from 'rxjs/operators';

export class FormUtil {
  public static checkValidity(formGroup: UntypedFormGroup): boolean {
    if (formGroup.invalid) {
      FormUtil.markAsTouched(formGroup);
    }

    return formGroup.valid;
  }

  public static markAsTouched(form: UntypedFormGroup | UntypedFormArray): void {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.markAsTouched(control);
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach((arrayControl: UntypedFormGroup) => {
          this.markAsTouched(arrayControl);
        });
      }
    });
  }

  public static rangeFilter(filters: any, from: any, to: any): void {
    if (from && (to === null || to === undefined || to === '')) {
      filters['max_units'] = { value: from, matchMode: 'greater' };
    } else if (to && (from === null || from === undefined || from === '')) {
      filters['max_units'] = { value: to, matchMode: 'less' };
    } else {
      filters['max_units'] = { value: [from, to], matchMode: 'between' };
    }
  }

  /**
   * Positive numbers and grater then 0
   */
  public static onlyPositiveAndValidDigitsValidator(control: UntypedFormControl): { [key: string]: any } {
    const value: any = control.value;

    if (value) {
      return value && value > 0
        ? null
        : {
            min: {
              value,
              min: 0,
            },
          };
    }
  }

  public static isUploadFilesValueValid(value: File[]): boolean {
    return value && Array.isArray(value) && value.length > 0;
  }

  public static validFormControls(form: UntypedFormGroup, validatedControls: string[]): boolean {
    return validatedControls.every((controlName) => {
      return form.controls[controlName].valid;
    });
  }

  public static resetFormArray(formArray: UntypedFormArray): UntypedFormArray {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }

    return formArray;
  }

  public static isEqualObjects(value: any, comparableValue: any): boolean {
    return JSON.stringify(value) === JSON.stringify(comparableValue);
  }

  public static transformRulesToValidator(key: string, prop: string): ValidatorFn {
    switch (key) {
      case 'min':
        return Validators.minLength(+prop);
      case 'required':
        return Validators.required;
      case 'ends_with':
        return endsWithValidator(prop);
      default:
        break;
    }
  }

  public static getChangedPropertyKey(data: object, savedData: object): string[] {
    const changedProperty: string[] = [];

    Object.keys(data).map((key) => {
      if (data[key] !== savedData[key]) {
        changedProperty.push(key);
      }
    });

    return changedProperty || null;
  }

  public static filtersFormListener(
    form: UntypedFormGroup,
    destroy$: Subject<boolean>,
  ): Observable<Record<string, any>> {
    return form?.valueChanges.pipe(
      debounceTime(555),
      startWith([null, form?.value]),
      pairwise(),
      filter(([oldValue, newValue]) => {
        return JSON.stringify(oldValue) !== JSON.stringify(newValue);
      }),
      map(([, newValue]) => {
        return newValue;
      }),
      takeUntil(destroy$),
    );
  }

  public static getCharacteristicValidations(fixedValue: string): { [key: string]: ValidatorFn[] } {
    return {
      validationsFloricode: fixedValue === '1' ? [Validators.required] : [],
      validationsAdjusting: fixedValue === '3' ? [Validators.required] : [],
    };
  }
}
